import React from "react";

import "./Tarifst.css";
import Addoperation from "./Hooks/Addoperation.js";
import { Tabs } from "antd";
import TableType from "./Hooks/TableType";
import AddAreaAndPrice from "./Hooks/AddAreaAndPrice.js";

function Tarifst() {
  return (
    <div className="traifst">
      <Tabs
        tabPosition="left"
        items={[
          {
            label: "Table de Tarifst",
            key: "Table_de_Tarifst",
            children: <TableType />,
          },
          {
            label: "Ajouter des Tarifst",
            key: "Ajouter_des_Tarifst",
            children: <AddAreaAndPrice />,
          },
        ]}
      />
    </div>
  );
}

export default Tarifst;
