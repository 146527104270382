// export const API_URL = `https://zazo.arachnotechfz.com/api`;
export const API_URL = `https://api.saclay.care/api`;
// export const API_URL = `http://192.168.43.76:8000/api`;

//auth
export const LOG_IN = `${API_URL}/login`;
export const REGISTER = `${API_URL}/register`;
export const LOG_OUT = `${API_URL}/logout`;
export const forget_password = `${API_URL}/forget_password`;
export const Check_Code_Of_Forget = `${API_URL}/Check`;
export const Check_Code_Of_Register = `${API_URL}/Check_Code`;
export const change_password = `${API_URL}/change_password`;
export const edit_profile = `${API_URL}/edit_profile`;
export const profile = `${API_URL}/profile`;

// Specialty
export const Store_Specialty = `${API_URL}/Store_Specialty`;
export const edit_Specialty = `${API_URL}/edit_Specialty`;
export const update_Specialty = `${API_URL}/update_Specialty`;
export const delete_Specialty = `${API_URL}/delete_Specialty`;
export const get_All_Specialties = `${API_URL}/get_All_Specialties`;
export const Get_All_Services_in_Specialty = `${API_URL}/Get_All_Services_in_Specialty`;
export const Get_All_Doctors_in_Specialty = `${API_URL}/Get_All_Doctors_in_Specialty`;
export const Get_All_News_in_Specialty = `${API_URL}/Get_All_News_in_Specialty`;
export const Get_Count_News_in_Specialty = `${API_URL}/Get_Count_News_in_Specialty`;

// Service

export const Store_Service = `${API_URL}/Store_Service`;
export const edit_Service = `${API_URL}/edit_Service`;
export const update_Service = `${API_URL}/update_Service`;
export const delete_Service = `${API_URL}/delete_Service`;
export const get_All_Services = `${API_URL}/get_All_Services`;
export const show_Service = `${API_URL}/show_Service`;

// Doctors
export const Store_Doctor = `${API_URL}/Store_Doctor`;
export const edit_Doctor = `${API_URL}/edit_Doctor`;
export const update_Doctor = `${API_URL}/update_Doctor`;
export const delete_Doctor = `${API_URL}/delete_Doctor`;
export const get_All_Doctors = `${API_URL}/get_All_Doctors`;
export const show_Doctor = `${API_URL}/show_Doctor`;

// News
export const Store_News = `${API_URL}/Store_News`;
export const edit_News = `${API_URL}/edit_News`;
export const update_News = `${API_URL}/update_News`;
export const delete_News = `${API_URL}/delete_News`;
export const get_All_News = `${API_URL}/get_All_News`;
export const show_News = `${API_URL}/show_News  `;

// Enviroment system
export const Add_email = `${API_URL}/Add_email`;
export const edit_email = `${API_URL}/edit_email`;
export const update_email = `${API_URL}/update_email`;
export const Add_phone_number = `${API_URL}/Add_phone_number`;
export const edit_phone_number = `${API_URL}/edit_phone_number`;
export const update_phone_number = `${API_URL}/update_phone_number`;
export const Add_whatsapp_url = `${API_URL}/Add_whatsapp_url`;
export const edit_whatsapp_url = `${API_URL}/edit_whatsapp_url`;
export const update_whatsapp_url = `${API_URL}/update_whatsapp_url`;
export const Add_facebook_url = `${API_URL}/Add_facebook_url`;
export const edit_facebook_url = `${API_URL}/edit_facebook_url`;
export const update_facebook_url = `${API_URL}/update_facebook_url`;
export const Add_doctolib_url = `${API_URL}/Add_doctolib_url`;
export const edit_doctolib_url = `${API_URL}/edit_doctolib_url`;
export const update_doctolib_url = `${API_URL}/update_doctolib_url`;
export const Add_Work_hours = `${API_URL}/Add_Work_hours`;
export const edit_Work_hours = `${API_URL}/edit_Work_hours`;
export const update_Work_hours = `${API_URL}/update_Work_hours`;
export const delete_Work_hours = `${API_URL}/delete_Work_hours`;
export const Get_Contact = `${API_URL}/Get_Contact`;
export const Get_All_Work_hours = `${API_URL}/Get_All_Work_hours`;

//Recommendation Messages

export const Srore_recommendation_messages = `${API_URL}/Srore_recommendation_messages`;
export const edit_recommendation_messages = `${API_URL}/edit_recommendation_messages`;
export const update_recommendation_messages = `${API_URL}/update_recommendation_messages`;
export const delete_recommendation_messages = `${API_URL}/delete_recommendation_messages`;
export const Get_All_recommendation_messages = `${API_URL}/Get_All_recommendation_messages`;

//type for store tarifst Messages

export const Srore_type = `${API_URL}/type/create`;
export const edit_type = `${API_URL}/type/edit`;
export const update_type = `${API_URL}/type/update`;
export const delete_type = `${API_URL}/type/delete`;
export const Get_All_type = `${API_URL}/type/get_all`;
export const Get_Areas_Price_Of_Type = `${API_URL}/type/get_all`;

//area and price Messages

export const Srore_area_price = `${API_URL}/area_price/create`;
export const edit_area_price = `${API_URL}/area_price/edit`;
export const update_area_price = `${API_URL}/area_price/update`;
export const delete_area_price = `${API_URL}/area_price/delete`;
export const Get_All_area_price = `${API_URL}/area_price/get_all`;
