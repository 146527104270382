import { lazy } from "react";
import Loadable from "../components/Loadable";
import MainLayout from "../Pages/Dashboard/Dashboard.js";
import Recommendation from "../Pages/Recommendation/Recommendation.js";
import Tarifst from "../Pages/Tarifst/Tarifst.js";

const Services = Loadable(lazy(() => import("../Pages/Services/Services")));
const Specialties = Loadable(
  lazy(() => import("../Pages/Specialties/Specialties"))
);
const News = Loadable(lazy(() => import("../Pages/News/News")));
const Doctors = Loadable(lazy(() => import("../Pages/Doctors/Doctors")));
const Contact = Loadable(lazy(() => import("../Pages/Contact/Contact")));
const Home = Loadable(lazy(() => import("../Pages/HomeDashboard/Home")));
const Setting = Loadable(lazy(() => import("../Pages/Setting/Setting.js")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/setting",
      element: <Setting />,
    },
    {
      path: "/services",
      element: <Services />,
    },
    {
      path: "/specialties",
      element: <Specialties />,
    },
    {
      path: "/news",
      element: <News />,
    },
    {
      path: "/doctors",
      element: <Doctors />,
    },
    {
      path: "/tarifst",
      element: <Tarifst />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/recommendation",
      element: <Recommendation />,
    },
  ],
};

export default MainRoutes;
