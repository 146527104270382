import React, { useState } from "react";
import { Table, Button, Modal, Form, Input, message } from "antd";
import { MdDelete } from "react-icons/md";

import { FaRegEdit } from "react-icons/fa";
import {
	Get_All_recommendation_messages,
	Srore_recommendation_messages,
	update_recommendation_messages,
} from "../../../api/Server";
import axios from "axios";
import { handleDelete } from "./Delete";
import { ExclamationCircleFilled } from "@ant-design/icons";
import "./Table.css";
import TextArea from "antd/es/input/TextArea";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Loader from "../../../components/Loader";
const { confirm } = Modal;
const RecommendationTable = () => {
	const [modalVisible, setModalVisible] = useState(false);
	const [form] = Form.useForm();
	const [editingRow, setEditingRow] = useState(null);
	const [messageApi, contextHolder] = message.useMessage();
	const pageSize = 4; // Define the maximum number of items per page
	const [currentPage, setCurrentPage] = useState(1); // Track the current page

	const fetchData = async () => {
		return await axios
			.get(Get_All_recommendation_messages)
			.then((res) => res.data.Recommendation_messages)
			.catch((error) => {
				console.error("Error fetching data:", error);
				return null;
			});
	};
	const { data, isLoading, isFetching } = useQuery({
		queryKey: ["Recommendation_messages"],
		queryFn: fetchData,
		refetchOnWindowFocus: false,
	});

	const slicedData = data?.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	const handleAdd = () => {
		form.resetFields();
		setModalVisible(true);
	};
	const handleEdit = (record) => {
		form.setFieldsValue(record);

		setEditingRow(record.id);
		setModalVisible(true);
	};
	const SaveRecommendationmessages = async (values) => {
		const url = editingRow
			? update_recommendation_messages
			: Srore_recommendation_messages;

		var requestOptions = editingRow
			? {
					body: {
						name: values?.name,
						message: values?.message,
						recommendation_id: values?.id,
					},
			  }
			: {
					body: {
						name: values?.name,
						message: values?.message,
					},
			  };
		try {
			const response = await axios.post(
				url,
				{ ...requestOptions.body },
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);

			setEditingRow(null);
			setModalVisible(false);

			if (response?.data.status === true) {
				message.success(response.data.msg, 2.5);
			} else {
				messageApi.open({
					type: "error",
					content: response.data.msg,
				});
			}
		} catch (error) {
			console.log("error", error);
		}
	};
	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: SaveRecommendationmessages,
		onSuccess: () => {
			queryClient.invalidateQueries(["Recommendation_messages"]);
		},
	});
	const handleSave = () => {
		form.validateFields().then(async (values) => {
			mutation.mutate(values);
		});
	};
	const mutationToDelelte = useMutation({
		mutationFn: handleDelete,
		onSuccess: () => {
			queryClient.invalidateQueries(["Recommendation_messages"]);
		},
	});
	const showDeleteConfirm = (record) => {
		confirm({
			title: "Êtes-vous sûr de supprimer ce message de recommandation ?",
			icon: <ExclamationCircleFilled />,
			content: `${record.name}`,
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk() {
				mutationToDelelte.mutate(record, messageApi);
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};
	const columns = [
		{ title: "Nom du testateur", dataIndex: "name", key: "name", width: "70%" },

		{
			title: "Modifier",
			render: (_, record) => (
				<>
					<Button
						type='link'
						size='large'
						onClick={() => handleEdit(record)}
						icon={<FaRegEdit />}
					/>
				</>
			),
		},
		{
			title: "supprimer",
			render: (_, record) => (
				<>
					<Button
						type='link'
						size='large'
						onClick={() => showDeleteConfirm(record)}
						danger
						icon={<MdDelete />}
					/>
				</>
			),
		},
	];
	if (isLoading) {
		return <Loader />;
	}
	return (
		<div>
			{contextHolder}
			{isFetching ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						margin: "auto",
						height: "500px",
					}}>
					<Loader />
				</div>
			) : (
				<Table
					dataSource={slicedData}
					columns={columns}
					title={() => (
						<div className='header-table'>
							<h3>Données des messages de recommandation</h3>
							<Button type='primary' onClick={handleAdd} className='btn'>
								Ajouter un message
							</Button>
						</div>
					)}
					expandable={{
						expandedRowRender: (record) => (
							<p
								key={record.id}
								style={{
									margin: 0,
								}}>
								{record.message}
							</p>
						),
					}}
					scroll={{
						y: 370,
					}}
					pagination={{
						pageSize: 3,
					}}
					rowKey='id'
				/>
			)}
			<Modal
				title={editingRow ? "Modifier le message" : "Ajouter un message"}
				visible={modalVisible}
				onCancel={() => {
					setEditingRow(null);
					setModalVisible(false);
				}}
				onOk={handleSave}>
				<Form form={form} layout='vertical' style={{ margin: "20px 5px" }}>
					<Form.Item name='id' label='ID' hidden>
						<Input />
					</Form.Item>
					<Form.Item
						name='name'
						label='Nom du testateur'
						rules={[{ required: true }]}
						style={{
							margin: "5px",
						}}>
						<Input size='large' placeholder='Entrez le nom du testateur' />
					</Form.Item>

					<Form.Item
						name='message'
						label='Message'
						rules={[{ required: true }]}
						style={{
							margin: "5px",
						}}>
						<TextArea
							placeholder='Entrez votre lettre de recommandation'
							allowClear
						/>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default RecommendationTable;
