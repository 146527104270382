import { Menu } from "antd";
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { LOG_OUT } from "../../api/Server";
import toast, { Toaster } from "react-hot-toast";
import { clearUser } from "../../store/User";

export default function UserMenu({ user }) {
	const token = user?.api_token;
	const dispatch = useDispatch();
	const nav = useNavigate();
	const handelLogout = async (token) => {
		try {
			const response = await axios.post(LOG_OUT, null, { token });
			console.log("Logout successful");
			toast.success(response.data.msg);
			dispatch(clearUser());
			nav("/login");
		} catch (error) {
			toast.error(error);
			console.error("Logout failed", error);
		}
	};

	return (
		<Menu>
			<Menu.Item key='logout' icon={<LogoutOutlined />}>
				<Link onClick={() => handelLogout(token)}>Logout</Link>
			</Menu.Item>
			<Menu.Item key='settings' icon={<SettingOutlined />}>
				<Link to='/setting'>Settings</Link>
			</Menu.Item>
			<Toaster position='bottom-right' reverseOrder={false} />
		</Menu>
	);
}
