import React, { useState } from "react";
import { Form, Input, Select, Radio, Button, message, Spin } from "antd";
import axios from "axios";
import { Get_All_type, Srore_area_price } from "../../../api/Server";
import { useQuery } from "react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { MdAdd } from "react-icons/md";
import "./styleForm.css";
const { Option } = Select;

function AddAreaAndPrice() {
  const [loading, setloading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = async (values) => {
    setloading(true);
    const requestOptions = {
      method: "POST",
      body: {
        area: values?.area,
        price: values?.price,
        gender: values?.gender,
        type_id: values?.type,
      },
      redirect: "follow",
    };
    try {
      await axios
        .post(
          Srore_area_price,
          {
            ...requestOptions.body,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response?.data.status == true) {
            messageApi
              .open({
                type: "loading",
                content: "Action in progress..",
                duration: 2.5,
              })
              .then(() => message.success("Ajouté avec succès", 2.5));
            setloading(false);
            window.location.reload();
          } else {
            setloading(false);

            messageApi.open({
              type: "error",
              content: response.data.msg,
            });
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchData = async () => {
    return await axios
      .get(Get_All_type)
      .then((res) => res.data.types)
      .catch((error) => {
        console.error("Error fetching data:", error);
        return null;
      });
  };
  const { data } = useQuery({
    queryKey: ["Types"],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
  });

  return (
    <div className="addpriceandarea">
      {contextHolder}
      <Form
        className="form-add-area"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, message: "Please select the type!" }]}
          layout="vertical"
        >
          <Select placeholder="Choisissez le type d'opération">
            {data?.map((el) => (
              <Option value={el.id} key={el.id}>
                {el.type}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Zone"
          name="area"
          rules={[{ required: true, message: "Please select the area!" }]}
        >
          <Input placeholder="Entrez le nom de la zone" />
        </Form.Item>

        <Form.Item
          label="Prix"
          name="price"
          rules={[{ required: true, message: "Please enter the price!" }]}
        >
          <Input placeholder="Entrez le prix" />
        </Form.Item>

        <Form.Item
          label="Genre"
          name="gender"
          rules={[{ required: true, message: "Please select the gender!" }]}
        >
          <Radio.Group>
            <Radio value="mâle">mâle</Radio>
            <Radio value="femelle">femelle</Radio>
            <Radio value="les deux">les deux</Radio>
            {/* <Radio value="other">Other</Radio> */}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          style={{
            margin: "10px 0px",
          }}
        >
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="forget-button"
          >
            {loading ? (
              <Spin indicator={<LoadingOutlined spin />} size="small" />
            ) : (
              <MdAdd />
            )}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default AddAreaAndPrice;
