import axios from "axios";
import { message } from "antd";
import { delete_type } from "../../../api/Server";

export const handleDelete = async (record, messageApi, contextHolder) => {
  try {
    const formdata = new FormData();
    formdata.append("type_id", record.id);

    const response = await axios.post(delete_type, formdata);

    messageApi
      .open({
        type: "loading",
        content: "Action in progress..",
        duration: 2.5,
      })
      .then(() => message.success(response.data.msg, 2.5));
  } catch (error) {
    console.error("Error", error);
  }
};
