import React, { useEffect, useState } from "react";
import "./Contact.css";
import { Button, Collapse } from "antd";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Get_Contact } from "../../api/Server";
import { PiPhoneCallFill } from "react-icons/pi";
import { FaFacebook, FaInstagram } from "react-icons/fa6";
import { IoLogoInstagram, IoLogoWhatsapp } from "react-icons/io";
import { useQuery } from "react-query";
function Contact() {
	const fetchData = async () => {
		return await axios
			.get(Get_Contact)
			.then((res) => res.data.Contact)
			.catch((error) => {
				console.error("Error fetching data:", error);
				return null;
			});
	};
	const nav = useNavigate();
	const { Panel } = Collapse;
	const { data } = useQuery({
		queryKey: ["Contact"],
		queryFn: fetchData,
		refetchOnWindowFocus: false,
	});

	return (
		<div className='contact'>
			<div className='contact-header'>
				<h4>Contact</h4>
				<Button
					type='link'
					onClick={() => {
						nav("/contact");
					}}>
					Edit
				</Button>
			</div>
			<div className='contact-elements'>
				<div className='contact-line-element'>
					<Collapse bordered={false}>
						<Panel
							header={
								<div className='title-contact-element'>
									<span>
										<PiPhoneCallFill />
									</span>
									<h6>Numéro de téléphone</h6>
								</div>
							}>
							<div className='wrap'>

								<a href={`tel:${data?.phone_number}`} target='_blank'>
									{data?.phone_number}
								</a>

							</div>
						</Panel>
					</Collapse>
				</div>

				<div className='contact-line-element'>
					<Collapse bordered={false}>
						<Panel
							header={
								<div className='title-contact-element'>
									<span>
										<MdEmail />
									</span>
									<h6>Mail</h6>
								</div>
							}>
							<div className='wrap'>
								<a href={`mailto:${data?.email}`} target='_blank'>
									{data?.email}
								</a>

							</div>
						</Panel>
					</Collapse>
				</div>
				<div className='contact-line-element'>
					<Collapse bordered={false}>
						<Panel
							header={
								<div className='title-contact-element'>
									<span>
										<FaInstagram />
									</span>
									<h6>Instagram</h6>
								</div>
							}>
							<div className='wrap'>
								<a href={`${data?.whatsapp_url}`} target='_blank'>
									{data?.whatsapp_url?.substring(0, 22)} ..
								</a>
							</div>
						</Panel>
					</Collapse>
				</div>
				<div className='contact-line-element'>
					<Collapse bordered={false}>
						<Panel
							header={
								<div className='title-contact-element'>
									<span>
										<FaFacebook />
									</span>
									<h6>FaceBook</h6>
								</div>
							}>
							<div className='wrap'>
								<a href={`${data?.facebook_url}`} target='_blank'>
									{data?.facebook_url?.substring(0, 22)} ..
								</a>
							</div>
						</Panel>
					</Collapse>
				</div>
				<div className='contact-line-element'>
					<Collapse bordered={false}>
						<Panel
							header={
								<div className='title-contact-element'>
									<span>
										<img
											src={require("../../assets/logo-blue.png")}
											width={14}
											alt='doctolib-logo'
										/>
									</span>
									<h6>Doctolib</h6>
								</div>
							}>
							<div className='wrap'>
								<a href={`${data?.doctolib_url}`} target='_blank'>
									{data?.doctolib_url?.substring(0, 22)} ..
								</a>
							</div>
						</Panel>
					</Collapse>
				</div>
			</div>
		</div >
	);
}

export default Contact;
