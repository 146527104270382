import React from "react";
import { Avatar, Button, Dropdown, Layout } from "antd";
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	UserOutlined,
} from "@ant-design/icons";
import UserMenu from "./UserMenu";
import { useSelector } from "react-redux";

function Header({ setCollapsed, collapsed }) {
	const { Header } = Layout;

	const toggleSidebar = () => {
		setCollapsed(!collapsed);
	};
	const user = useSelector((state) => state.user);

	return (
		<Header
			style={{
				background: "#fff",
				padding: "0px 20px",
				display: "flex",
				justifyContent: "space-around",
				alignItems: "center",
			}}>
			{collapsed ? (
				<div>
					<MenuUnfoldOutlined className='trigger' onClick={toggleSidebar} />
				</div>
			) : (
				<MenuFoldOutlined className='trigger' onClick={toggleSidebar} />
			)}
			<div style={{ marginLeft: "auto", paddingRight: 16 }}>
				<Dropdown overlay={UserMenu(user)} trigger={["click"]}>
					<Button type='text' style={{ padding: 0 }}>
						<Avatar icon={<UserOutlined />} src={user.image_url} />
						<span style={{ marginLeft: 8 }}>{user.name}</span>
					</Button>
				</Dropdown>
			</div>
		</Header>
	);
}

export default Header;
