import { Spin } from "antd";
import React from "react";

// ==============================|| LOADER ||============================== //
const Loader = () => (
	<>
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				margin: "auto",

			}}>
			<Spin />
		</div>
	</>
);

export default Loader;
