import React from "react";
import NavigationScroll from "./Pages/Dashboard/NavigationScroll";
import "./App.css";
import ThemeRoutes from "./Routes";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();
const App = () => {
  return (
    <ConfigProvider
      locale={frFR}
      theme={{
        token: {
          colorPrimary: "#232b61",
          colorInfo: "#232b61",
        },
      }}
    >
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <NavigationScroll>
            <ThemeRoutes />
          </NavigationScroll>
        </QueryClientProvider>
      </div>
    </ConfigProvider>
  );
};

export default App;
