import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Table,
} from "antd";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleDelete } from "./DeleteType";
import Loader from "../../../components/Loader";
import { MdDelete } from "react-icons/md";
import { ExclamationCircleFilled } from "@ant-design/icons";

import { FaRegEdit } from "react-icons/fa";
import axios from "axios";
import {
  Get_All_type,
  Srore_type,
  update_area_price,
  update_type,
} from "../../../api/Server";
import { handleDeleteArea } from "./DeleteArea";

function TableType() {
  const { Loading, setloading } = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAreaVisible, setModalAreaVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingRow, setEditingRow] = useState(null);
  const [editingRowAreaprice, setEditingRowAreaprice] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const pageSize = 4; // Define the maximum number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const { Option } = Select;

  const fetchData = async () => {
    return await axios
      .get(Get_All_type)
      .then((res) => res.data.types)
      .catch((error) => {
        console.error("Error fetching data:", error);
        return null;
      });
  };
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["Types"],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
  });

  const slicedData = data?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleAdd = () => {
    form.resetFields();
    setModalVisible(true);
  };
  const handleEdit = (record) => {
    form.setFieldsValue(record);
    setEditingRow(record.id);
    setModalVisible(true);
  };

  const saveType = async (values) => {
    const url = editingRow ? update_type : Srore_type;

    var requestOptions = editingRow
      ? {
          body: {
            type: values?.type,
            type_id: values?.id,
          },
        }
      : {
          body: {
            type: values?.type,
          },
        };
    try {
      const response = await axios.post(
        url,
        { ...requestOptions.body },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setEditingRow(null);
      setModalVisible(false);

      if (response?.data.status === true) {
        message.success(response.data.msg, 2.5);
      } else {
        messageApi.open({
          type: "error",
          content: response.data.msg,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: saveType,
    onSuccess: () => {
      queryClient.invalidateQueries(["Types"]);
    },
  });

  const onFinish = async () => {
    form.validateFields().then(async (values) => {
      const url = update_area_price;

      var requestOptions = {
        body: {
          area_price_id: values?.id,
          area: values?.area,
          price: values?.price,
          gender: values?.gender,
          type_id: values?.type_id,
        },
      };

      try {
        const response = await axios.post(
          url,
          { ...requestOptions.body },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setEditingRow(null);
        setModalVisible(false);

        if (response?.data.status === true) {
          message.success(response.data.msg, 2.5);
          window.location.reload();
        } else {
          messageApi.open({
            type: "error",
            content: response.data.msg,
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    });
  };
  const mutationArea = useMutation({
    mutationFn: onFinish,
    onSuccess: () => {
      queryClient.invalidateQueries(["Types"]);
    },
  });
  const handleSave = () => {
    form.validateFields().then(async (values) => {
      mutation.mutate(values);
      mutationArea.mutate(values);
    });
  };
  const mutationToDelelte = useMutation({
    mutationFn: handleDelete,
    onSuccess: () => {
      queryClient.invalidateQueries(["Types"]);
    },
  });
  const mutationToDelelteArea = useMutation({
    mutationFn: handleDeleteArea,
    onSuccess: () => {
      queryClient.invalidateQueries(["Types"]);
    },
  });
  const { confirm } = Modal;
  const showDeleteConfirm = (record) => {
    confirm({
      title: "Êtes-vous sûr de supprimer ce type ?",
      icon: <ExclamationCircleFilled />,
      content: `${record.type}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        mutationToDelelte.mutate(record, messageApi);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const expandedRowRender = (datarow) => {
    const handelEditArea = (record, type, typeid) => {
      form.setFieldsValue({
        ...record,
        type: type,
        type_id: typeid,
      });
      setEditingRowAreaprice(record.id);
      setModalAreaVisible(true);
    };
    const showDeleteAreaConfirm = (record) => {
      confirm({
        title: "Êtes-vous sûr de supprimer la zone et le prix  ?",
        icon: <ExclamationCircleFilled />,
        content: `${record.area}`,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          mutationToDelelteArea.mutate(record, messageApi);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    };
    const columns = [
      {
        title: "Area",
        dataIndex: "area",
        key: "area",
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
      },
      {
        title: "Modifier",
        render: (_, record) => (
          <>
            <Button
              type="link"
              size="large"
              onClick={() => {
                handelEditArea(record, datarow.type, datarow.id);
              }}
              icon={<FaRegEdit />}
            />
          </>
        ),
      },
      {
        title: "supprimer",
        render: (_, record) => (
          <>
            <Button
              type="link"
              size="large"
              onClick={() => showDeleteAreaConfirm(record)}
              danger
              icon={<MdDelete />}
            />
          </>
        ),
      },
    ];

    return (
      <div>
        <Table
          columns={columns}
          dataSource={datarow?.areas_and_prices}
          pagination={false}
        />
        <Modal
          title={
            editingRowAreaprice ? "Modifier le zone " : "Ajouter un message"
          }
          visible={modalAreaVisible}
          onCancel={() => {
            setEditingRowAreaprice(null);
            setModalAreaVisible(false);
          }}
          onOk={onFinish}
        >
          <Form
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            layout="vertical"
          >
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label="Type"
              name="type"
              // rules={[{ required: true, message: "Please select the type!" }]}
              layout="vertical"
            >
              <Select placeholder="Choisissez le type d'opération">
                {data?.map((el) => (
                  <Option value={el.id} key={el.id}>
                    {el.type}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Zone"
              name="area"
              rules={[{ required: true, message: "Please select the area!" }]}
            >
              <Input placeholder="Entrez le nom de la zone" />
            </Form.Item>
            <Form.Item name="type_id" hidden>
              <Input />
            </Form.Item>

            <Form.Item
              label="Prix"
              name="price"
              rules={[{ required: true, message: "Please enter the price!" }]}
            >
              <Input placeholder="Entrez le prix" />
            </Form.Item>

            <Form.Item
              label="Genre"
              name="gender"
              rules={[{ required: true, message: "Please select the gender!" }]}
            >
              <Radio.Group>
                <Radio value="mâle">mâle</Radio>
                <Radio value="femelle">femelle</Radio>
                <Radio value="les deux">les deux</Radio>
                {/* <Radio value="other">Other</Radio> */}
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  };
  const columns = [
    { title: "Type", dataIndex: "type", key: "type", width: "70%" },
    {
      title: "Modifier",
      render: (_, record) => (
        <>
          <Button
            type="link"
            size="large"
            onClick={() => handleEdit(record)}
            icon={<FaRegEdit />}
          />
        </>
      ),
    },
    {
      title: "supprimer",
      render: (_, record) => (
        <>
          <Button
            type="link"
            size="large"
            onClick={() => showDeleteConfirm(record)}
            danger
            icon={<MdDelete />}
          />
        </>
      ),
    },
  ];
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      {contextHolder}
      {isFetching ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            height: "500px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <Table
          dataSource={slicedData}
          columns={columns}
          title={() => (
            <div className="header-table">
              <h3>Données de prix</h3>
              <Button type="primary" onClick={handleAdd} className="btn">
                Ajouter un Type
              </Button>
            </div>
          )}
          expandable={{
            expandedRowRender,
            defaultExpandedRowKeys: ["0"],
          }}
          pagination={{
            pageSize: 3,
          }}
          rowKey="id"
        />
      )}
      <Modal
        title={editingRow ? "Modifier le message" : "Ajouter un message"}
        visible={modalVisible}
        onCancel={() => {
          setEditingRow(null);
          setModalVisible(false);
        }}
        onOk={handleSave}
      >
        <Form form={form} layout="vertical" style={{ margin: "20px 5px" }}>
          <Form.Item name="id" label="ID" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label="Nom du testateur"
            rules={[{ required: true }]}
            style={{
              margin: "5px",
            }}
          >
            <Input size="large" placeholder="Entrez le nom du testateur" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default TableType;
