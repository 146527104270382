import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	user: JSON.parse(localStorage.getItem("user")) || {
		api_token: "",
		created_at: "",
		email: "",
		email_verified_at: null,
		name: "",
		id: 0,
		image_url: "",
		updated_at: "",
	},
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = {
				...action.payload.info,
			};
			localStorage.setItem("user", JSON.stringify(action.payload.info));
		},
		clearUser: (state) => {
			state.user = {
				api_token: "",
				created_at: "",
				email: "",
				email_verified_at: null,
				name: "",
				id: 0,
				image_url: "",
				updated_at: "",
			};
			localStorage.removeItem("user");
		},
	},
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
