import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import React from "react";
import "../../styles/Styles.css";
import { FaBriefcaseMedical, FaUserDoctor } from "react-icons/fa6";
import { PiNewspaperFill, PiPhoneCallFill } from "react-icons/pi";
import { MdHome } from "react-icons/md";
import { TbBrandFeedly } from "react-icons/tb";
import { MdOutlineMessage } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoMdSettings } from "react-icons/io";
import { IoMdPricetag } from "react-icons/io";
function ListItems({ collapsed, toggleSidebar }) {
  const date = new Date();
  return (
    <Sider
      className="sideBar"
      style={{ position: "fixed", height: "100vh", left: 0, zIndex: 100000000 }}
      width={200}
      collapsible={collapsed ? true : false}
      onCollapse={toggleSidebar}
      collapsed={collapsed}
    >
      <div className="logo">
        {collapsed ? (
          <Link to={"/"}>
            <img
              src={require("../../assets/logo_saclay-small.png")}
              alt="saclay logo"
            />
          </Link>
        ) : (
          <Link to={"/"}>
            <img
              src={require("../../assets/logo_saclay-removebg-preview 1.png")}
              alt="saclay logo"
            />
          </Link>
        )}
      </div>
      <Menu mode="vertical" className="menu">
        <Menu.Item key="1" icon={<MdHome />}>
          <Link to={"/"}>Accueil</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<TbBrandFeedly />}>
          <Link to={"/specialties"}>Spécialités</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<FaBriefcaseMedical />}>
          <Link to={"/services"}>Services</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<FaUserDoctor />}>
          <Link to={"/doctors"}>Médecins</Link>
        </Menu.Item>
        {/* <Menu.Item key="5" icon={<PiNewspaperFill />}>
          <Link to={"/news"}>Actualités</Link>
        </Menu.Item> */}
        <Menu.Item key="5" icon={<IoMdPricetag />}>
          <Link to={"/tarifst"}>Tarifst</Link>
        </Menu.Item>
        <Menu.Item key="6" icon={<PiPhoneCallFill />}>
          <Link to={"/contact"}>Contacts</Link>
        </Menu.Item>
        <Menu.Item key="7" icon={<MdOutlineMessage />}>
          <Link to={"/recommendation"}>Recommendation</Link>
        </Menu.Item>
        <Menu.Item key="8" icon={<IoMdSettings />}>
          <Link to={"/setting"}>Paramètres</Link>
        </Menu.Item>
      </Menu>
      {collapsed ? (
        <></>
      ) : (
        <div className="logo-footer">
          <img
            src={require("../../assets/logo-footer.png")}
            alt="© 2024 SACLAY CARE CENTER "
          />
          <p>© {date.getFullYear()} SACLAY CARE CENTER </p>
        </div>
      )}
    </Sider>
  );
}

export default ListItems;
