import { lazy } from "react";
import React from "react";

// project imports
import MinimalLayout from "../Pages/Dashboard/MinimalLayout";
import Loadable from "../components/Loadable";

// login option 3 routing
const Login = Loadable(lazy(() => import("../Pages/Auth/Login/Login")));
const ForgotPasswordForm = Loadable(
	lazy(() => import("../Pages/Auth/ForgetPassword"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
	path: "/*",
	element: <MinimalLayout />,
	children: [
		{
			path: "login",
			element: <Login />,
		},
		{
			path: "forgot-password",
			element: <ForgotPasswordForm />,
		},
	],
};

export default AuthenticationRoutes;
