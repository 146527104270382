import React, { useState } from "react";
import { Layout } from "antd";
import ListItems from "./ListItems";
import Header from "./Header";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function Dashboard() {
	const token = useSelector((state) => state.user.api_token);
	const [collapsed, setCollapsed] = useState(false);
	const toggleSidebar = () => {
		setCollapsed(!collapsed);
	};
	return !token ? (
		<Navigate to='/login' />
	) : (
		<Layout style={{ minHeight: "100vh" }}>
			<ListItems collapsed={collapsed} toggleSidebar={toggleSidebar} />
			<Layout style={{ marginLeft: collapsed ? 80 : 200 }}>
				<Header setCollapsed={setCollapsed} collapsed={collapsed} />
				<Outlet />
			</Layout>
		</Layout>
	);
}

export default Dashboard;
