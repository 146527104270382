import React from "react";
import RecommendationTable from "./Hooks/Table";
import Contact from "../../components/Contacts/Contact";
import "./Recommendation.css";

function Recommendation() {
	return (
		<div className='Recommendation'>
			<div className='Recommendation-element-grigs'>
				<RecommendationTable />
			</div>
			<div className='Recommendation-element-calender-contact'>
				<Contact />
			</div>
		</div>
	);
}

export default Recommendation;
